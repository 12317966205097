import Joi from "joi";
import errorMessages from "./errorMessages.json";
import nameRegex from "@/validation-schemas/custom/nameRegex";

export default Joi.object({
  name: Joi.string()
    .max(50)
    .pattern(nameRegex.name)
    .required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().allow(null, "")
})
  .unknown()
  .messages(errorMessages);
