<template>
  <div class="register">
    <div class="card overflow-hidden">
      <div class="bg-soft-primary">
        <div class="row">
          <div class="col-7">
            <div class="text-primary p-4">
              <h5 class="text-primary">Sign up</h5>
              <p>Create a distribution account</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div>
          <router-link tag="a" to="/">
            <div class="avatar-md profile-user-wid mb-4">
              <span class="avatar-title rounded-circle bg-light">
                <img src="@/assets/images/logo.png" alt height="34" />
              </span>
            </div>
          </router-link>
        </div>
        <b-alert :show="!!error" variant="danger" class="mt-3" dismissible>{{
          error
        }}</b-alert>

        <!-- <div
              v-if="notification.message"
              :class="'alert ' + notification.type"
            >{{notification.message}}</div> -->

        <b-form class="p-2" @submit.prevent="register">
          <b-form-group id="input-group-1" label="Name" label-for="input-1">
            <b-form-input
              v-model="name"
              type="text"
              placeholder="Enter name"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-1" label="Email" label-for="input-1">
            <b-form-input
              v-model="email"
              type="text"
              placeholder="Enter email"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Password" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="password"
              type="password"
              placeholder="Enter password"
            ></b-form-input>
          </b-form-group>
          <div class="mt-3">
            <ebp-button
              :loading="loading"
              type="submit"
              variant="primary"
              className="btn-block"
              >Create Account</ebp-button
            >
          </div>
        </b-form>
      </div>
    </div>

    <div class="mt-5 text-center">
      <p>
        Already have an account ?
        <router-link
          tag="a"
          :to="{ name: 'Login' }"
          class="font-weight-medium text-primary"
          >Sign in</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import userSchema from "@/validation-schemas/user";

export default {
  metaInfo() {
    return {
      title: this.$t("register")
    };
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      submitted: false,
      error: null,
      loading: false
    };
  },
  methods: {
    async register() {
      this.error = null;

      const user = {
        name: this.name,
        email: this.email,
        password: this.password
      };

      const validation = userSchema.validate(user, this.joiOptions);

      if (validation.error) {
        this.error = this.$t(validation.error.message);
      } else {
        this.submitted = true;
        this.loading = true;

        try {
          await this.$store.dispatch("auth/register", user);
          this.$router.push({ name: "ClientDashboard" });
        } catch (err) {
          this.submitted = false;
          this.error = this.errorRes(err);
        }

        this.loading = false;
      }
    }
  }
};
</script>
